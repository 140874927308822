<!-- 真人视讯 -->
<template>
    <div class="page-mobile-bet">
        <div class="bet-box">
            <div class="bet-img">
                <img loading="lazy" v-lazy="require('../../assets/images/other/black_app_a001_left.png')"> 
            </div>
            <div class="bet-right">
                <div class="title">{{$t("homePageIndex.text40")}}</div>
                <div class="text1">{{$t("homePageIndex.text41")}}</div>
                <div class="text2">{{$t("homePageIndex.text42")}}</div>
                <div>{{$t("homePageIndex.text43")}}</div>
                <div>{{$t("homePageIndex.text44")}}</div>
                <div class="bet-qrcode">
                    <div class="qrcode-left">
                        <div class="qrcode-boxs">
                            <div class="qrcode-box">
                                <div id="qrcodeApp"></div>
                            </div>
                        </div>
                        <div>{{$t("homePageIndex.text45")}}</div>
                        <div>{{$t("homePageIndex.text46")}}</div>
                    </div>
                    <div class="qrcode-right qrcode-left">
                        <div class="qrcode-boxs">
                            <img loading="lazy" v-lazy="require('../../assets/images/other/black_app_a001_h5.png')">
                        </div>
                        <div>{{$t("homePageIndex.text47")}}</div>
                        <div>http://www.google.com</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QRCode from '@keeex/qrcodejs-kx'
import { _downloadApp } from '@/core/utils/utils'
export default {
    data () {
        return {
            
        }
    },
    mounted() {
        this.qrcode('qrcodeApp', _downloadApp())
        
    },
    methods: {
       
        qrcode(qrcodeId, qrcode) {
            new QRCode(qrcodeId, {
                'width': 148, // 设置宽度，单位像素
                'height': 148, // 设置高度，单位像素
                'text': qrcode // 设置二维码内容或跳转地址
            });
        }
    }
}
</script>

<style lang="less" scoped>

@import url("../../assets/css/page-mobile-bet.less");

</style>